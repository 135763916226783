import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useLocation, useHistory } from "react-router-dom";
import { Card, Grid, Typography } from "@material-ui/core";
import { getAllDistrictsIn } from "../../../redux/actions/location"
import { scroller } from "react-scroll";

function ToSeoUrl(url) {
    if (url != "") {
        var encodedUrl = url?.toString().toLowerCase();
        encodedUrl = encodedUrl?.split(/\&+/).join("-and-")
        encodedUrl = encodedUrl?.split(/[^a-z0-9]/).join("-");
        encodedUrl = encodedUrl?.split(/-+/).join("-");
        encodedUrl = encodedUrl?.trim('-');
        return encodedUrl;
    }
}
function findValueInArray(value, arr) {
    var result = false;
    for (var i = 0; i < arr.length; i++) {
        var name = arr[i];
        if (name == value) {
            result = true;
            break;
        }
    }

    return result;
}
const DistListView = () => {
    let [groupedCategories, setGroupedCategories] = useState([]);
    // const alphabets = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'X', 'Y', 'Z']
    const history = useHistory();
    const location = useLocation();
    const { districtIn } = useSelector(({ location }) => location);
    const dispatch = useDispatch();
    let urls = location.pathname.split("/");
    useEffect(() => {
        getProfessions()
    }, [districtIn])
    useEffect(() => {
        let urls = location.pathname.split("/");
        dispatch(getAllDistrictsIn(urls[4]));
    }, [])
    let scrollToSection = (scrollId) => {
        scroller.scrollTo(scrollId, {
            duration: 800,
            delay: 0,
            smooth: "easeInOutQuart",
        });
    };

    function navigate(region, districts) {
        var urlregion = ToSeoUrl(region)
        if (districts) {
            var urldistricts = ToSeoUrl(districts)
            history.push("/" + urls[1] + "/" + urls[2] + "/" + urls[3] + "/" + urlregion + "/" + urldistricts)
        } else {
            history.push("/" + urls[1] + "/" + urls[2] + "/" + urls[3] + "/" + urlregion)
        }
        window.scrollTo(0, 0)
    }

    let getProfessions = async () => {

        let data = districtIn.reduce((r, e) => {
            // get first letter of name of current element
            //console.log(r)
            //let group = e.region[0];
            let group = e.state;
            // if there is no property in accumulator with this letter create it
            if (!r[group]) r[group] = { group, children: [e] }
            // if there is push current element to children array for that letter
            else r[group].children.push(e);
            // return accumulator
            return r;
        }, {})
        let result = Object.values(data)
        result.sort(function (a, b) {
            return a.group.localeCompare(b.group);
        })
        console.log(result)
        setGroupedCategories(result);
    };

    return (
        <div className={'col-xs-12 col-md-12 slp-main-content'}>
            <Card className="p-6" style={{ padding: '2%' }}>
                <Typography variant="h5" className="mb-2">Our Services in </Typography>
                {/* <div className=" flex-row justify-between align-content-center">
                    {groupedCategories.map((item, index) => {
                        return <span key={index} onClick={()=> scrollToSection(item.group)}
                                     className="sm:hover:underline cursor-pointer font-weight-bolder text-primary" style={{margin:'0% 4% 0% 0%'}}>{item.group}</span>
                    })}
                </div> */}

                <div className="flex-col">
                    {

                        //console.log("children=",item.children[index]?.state)
                        //if (ToSeoUrl(districtIn[0]?.state) == ToSeoUrl(urls[4])) {
                        //var districtArr = [];
                        <div style={{ marginTop: '5vh' }}>
                            <Typography variant="h4" className="mb-4 cursor-pointer" onClick={() => navigate(districtIn[0]?.state)} >{districtIn[0]?.state}</Typography>
                            <Grid container spacing={1}>
                                {districtIn.map((item, index) => {
                                    //{item.children.map((datum, ind) => {
                                    //if (!findValueInArray(datum.districts, districtArr)) {
                                    //districtArr.push(datum.districts)
                                    return <Grid item key={index} xs={12} sm={6} md={4} lg={3}
                                        onClick={() => navigate(item.state, item._id)}
                                        className="text-primary sm:hover:underline cursor-pointer">
                                        {item._id}
                                    </Grid>
                                    // })
                                    //}
                                })}
                                {
                                    //console.log(districtArr)
                                }
                            </Grid>
                        </div>
                        //}
                    }
                </div>
            </Card>
        </div>
    )
}

export default DistListView
