import React from 'react';
import { useParams, useLocation, useHistory } from "react-router-dom";
import HomeIcon from '@mui/icons-material/Home';
import PublicIcon from '@mui/icons-material/Public';
import AssistantPhotoIcon from '@mui/icons-material/AssistantPhoto';
import MiscellaneousServicesIcon from '@mui/icons-material/MiscellaneousServices';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import NearMeIcon from '@mui/icons-material/NearMe';
const capitalize = (str) => {
  if (typeof str != "undefined")
    return str.charAt(0).toUpperCase() + str.slice(1);
}
const BreadCrumb = ({ servicedetails, locality, territory }) => {
  const history = useHistory();
  const location = useLocation();
  let urls = location.pathname.split("/");

  var { service, area, locations } = useParams();
  let Local = '';
  let Post = '';
  if (locations) {
    let locarray = locations.split("+");
    Local = locarray[0].replace('-', ' ');
    Post = locarray[1];
  }
  return (
    <div className="dark placeholder">

      <div className="breadcrumb">
        <a onClick={() => { history.push("/") }}><HomeIcon className='breadcrumbIcon' /> Home</a>
        {urls[1] && <a onClick={() => { history.push("/" + urls[1]) }}><PublicIcon className='breadcrumbIcon' /> Country</a>}
        {urls[2] && <a onClick={() => { history.push("/" + urls[1] + "/" + urls[2]) }}><AssistantPhotoIcon className='breadcrumbIcon' /> Australia</a>}
        {servicedetails && servicedetails != "NAN" && <a onClick={() => { history.push("/" + urls[1] + "/" + urls[2] + "/" + urls[3] + "/" + urls[4]) }}>
          <MiscellaneousServicesIcon className='breadcrumbIcon' /> {capitalize(servicedetails.name)}
        </a>}
        {territory && territory != "NAN" && <a onClick={() => { history.push("/" + urls[1] + "/" + urls[2] + "/" + urls[3] + "/" + urls[4]) }}>
          <LocationOnIcon className='breadcrumbIcon' /> {capitalize(territory)}
        </a>}
        {Local && Local != "" && <a onClick={() => { history.push("/" + urls[1] + "/" + urls[2] + "/" + urls[3] + "/" + urls[4] + "/" + urls[5]) }}>
          <NearMeIcon className='breadcrumbIcon' /> {capitalize(Local)}
        </a>}
      </div>
    </div>
  );
}
export default BreadCrumb;