import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setIpInfo, getIpInfo } from "../../../redux/actions/location"

const Footer = () => {
	const dispatch = useDispatch()
	const { iframeUrl } = useSelector(({ location }) => location);
	useEffect(() => {
		if (iframeUrl === "") {
			dispatch(getIpInfo())
		}
	}, [iframeUrl])

	return (
		<div>
			<div className="footer">
				<div className="container text-center">
					<div className="row">
						<div className="col-sm-6 margin-bottom-20">
							<h2 className="text-uppercase">Head Office</h2>
							<p></p><address>48 Edinburgh Road, <br />Mooroolbark, <br />Victoria 3138 </address><p></p>
							<p><a href="https://www.google.com.au/maps/place/48+Edinburgh+Road%2CMooroolbark%2CVictoria%2B3138/@-37.7866911,145.3507494,17z/data=!3m1!4b1!4m5!3m4!1s0x6ad62fa4ee34d993:0xdee21373d503a80f!8m2!3d-37.7866911!4d145.3529434" rel="nofollow noopener" target="_blank"></a></p>
						</div>
						<div className="col-sm-6 margin-bottom-20">
							<h2 className="text-uppercase">Operating Hours</h2>
							<div className="wbtrnws-opening-times">Mon-Fri - 7am-8pm<br />Sat-Sun - 8am-4pm<br /></div>					</div>
						<div className="col-xs-12 text-center">
							<div className="menu-footer-menu-container"><ul id="menu-footer-menu" className="footer-menu"><li id="menu-item-6713" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-6713"><a href={`${iframeUrl}/franchisee-hub/`}>Franchisor Hub</a></li>
								<li id="menu-item-6714" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-6714"><a href={`${iframeUrl}/franchisee-hub/`}>Franchisee Hub</a></li>
							</ul></div>				</div>
					</div>
				</div>
			</div>
			<div className="footer-bottom">

				<div className="container text-center copyright">
					Copyright © {new Date().getFullYear()} tradingseek.com All Rights Reserved.
				</div>

			</div>
		</div>
	);
}
export default Footer;