import React from "react";
import {
        FacebookShareButton, 
        FacebookIcon,
        TwitterShareButton,
        TwitterIcon,
        WhatsappShareButton,
        WhatsappIcon,
    } from "react-share";
       
 const SosialShare = (props) => {
       return (
           <>
             <FacebookShareButton 
                url={`${process.env.REACT_APP_URL}/${props.url}`}
                quote={`${props.quote}`}
                hashtag={`${props.hashtag}`}
                >
                 <FacebookIcon size={26} round={true} />
              </FacebookShareButton>
              <TwitterShareButton
                url={`${process.env.REACT_APP_URL}`}
                title={`${props.quote}`}
                hashtag={`${props.hashtag}`}
            >
                <TwitterIcon size={26} round={true} />
            </TwitterShareButton>
            <WhatsappShareButton
                url={`${process.env.REACT_APP_URL}`}
                title={`${props.quote}`}
                separator=":: "
            >
                <WhatsappIcon size={26} round={true} />
            </WhatsappShareButton>
              </>
       );
}
export default SosialShare;