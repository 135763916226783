import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from "react-router-dom";
import Map from './mapLanding';
import { useDispatch, useSelector } from 'react-redux';
import { getAllStateIn } from "../../../redux/actions/location"

function ToSeoUrl(url) {
    if (url != "") {
        var encodedUrl = url?.toString().toLowerCase();
        encodedUrl = encodedUrl?.split(/\&+/).join("-and-")
        encodedUrl = encodedUrl?.split(/[^a-z0-9]/).join("-");
        encodedUrl = encodedUrl?.split(/-+/).join("-");
        encodedUrl = encodedUrl?.trim('-');
        return encodedUrl;
    }
}
const groupBy = (array, key) => {
    var data = [];
    array.map((result, indx) => {
        data.push(result[key])
    })
    data = [...new Set(data)]
    return data.sort();
};
// const UniqueRegion = groupBy(LocationData, 'state');
//console.log(UniqueRegion);
const LandingLeft = ({ servicedetails, locality, territory, state }) => {
    const history = useHistory();
    const location = useLocation();

    const dispatch = useDispatch();

    const { stateIn } = useSelector(({ location }) => location);
    useEffect(() => {
        dispatch(getAllStateIn())
    }, [])

    return (
        <div className={'col-xs-12  col-sm-12 col-md-12 col-lg-8 slp-main-content'} style={{ textAlign: 'center' }}>
            <h2>OUR SERVICES IN </h2>
            <div className="slp-links-panel text-center">
                {
                    stateIn?.map((location, index) => {
                        return (
                            <a onClick={() => { history.push("/local/in/" + ToSeoUrl(location._id)) }} className="btn btn-inline btn-primary-mod btn-lg">
                                {location._id}
                            </a>
                        );
                    })
                }
            </div>
            <div style={{ textAlign: 'justify', marginTop: '10vh' }}>
                <h3>Content Heading Gose Here</h3>

                <p>
                    Need Cleaners in West End?  Call TradingSeek West End for all your domestic and commercial
                    cleaning services in West End.  Our professional team are available 7 days a week; book a free
                    quote online or call 123 456.
                </p>
            </div>
            <div>
                {/* <img loading="lazy" style={{float: 'right',margin:'0px 20px',width:'95%'}}
        src={"https://images.mapsofworld.com/answers/2020/07/new-zealand-map.jpg"}
        alt="Home Cleaning Services" />
         */}
                <Map />
            </div>
        </div>
    );
}
export default LandingLeft;