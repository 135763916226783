import React from 'react';
import { useParams } from "react-router-dom";
import BreadCrumb from "./breadcrumb"
const Banner = ({ servicedetails, locality, dist, territory }) => {
    console.log(servicedetails, locality, dist, territory)
    var { service, area, locations } = useParams();
    return (
        <div>
            <div style={{ backgroundImage: 'url("/images/banner.png")', backgroundSize: 'cover', padding: '8%', textAlign: 'center', color: '#fff' }}>
                <span style={{ textTransform: 'uppercase', fontWeight: '100' }}><h3 style={{ color: '#fff' }}>{servicedetails?.name} {area && "SERVICES IN"} {!servicedetails && !area ? "WELCOME TO TRADINGSEEK" : ""} {locality && locality != null ? locality : '' || dist && dist != null ? dist : '' || territory && territory != null ? territory : '' || area && area != null ? area : ''} </h3></span>
            </div>
            <div style={{ backgroundImage: 'url("/images/banner.png")', backgroundSize: 'cover', padding: '0.1%', textAlign: 'center', color: '#fff' }}>
                <BreadCrumb servicedetails={servicedetails} locality={locality} dist={dist} territory={territory} />
            </div>
        </div>

    );
}
export default Banner;