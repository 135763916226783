import React from 'react';
import { Oval } from 'react-loader-spinner';

import './mainLoader.css';

const mainLoader = (props) => {
    return (
        <div className={`main_loader_div ${props.small && 'small_loader'}`}>
            <Oval
                height={60}
                width={60}
                color="#1a3490"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
                ariaLabel='oval-loading'
                secondaryColor="#667eea"
                strokeWidth={2}
                strokeWidthSecondary={2}

            />
        </div>
    )
}
export default mainLoader;