import {
    SET_ALL_PROFESSIONS, SET_PROFESSION,
} from '../constants/professionActionTypes'
const INIT_STATE = {
    groupedCategories: [],
    servicedetails: null,
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case SET_ALL_PROFESSIONS: {
            return {
                ...state,
                groupedCategories: action.payload,
            };
        }
        case SET_PROFESSION: {
            return {
                ...state,
                servicedetails: action.payload,
            };
        }
        default:
            return state;
    }
}