// const proxyurl = "https://cors-anywhere.herokuapp.com/";
var serverUrl = `https://api.service.tradingseek.com`;
var iframeWebsiteUrl = `https://tradingseek.net`;

console.log('Environment: ', process.env.REACT_APP_ENVIRONMENT);
if (process.env.REACT_APP_ENVIRONMENT === 'development') {
    serverUrl = `http://localhost:8000`;
    iframeWebsiteUrl = `https://tradingseek.net`;
}


export const rootUrl = serverUrl;
export const iframeWebUrl = iframeWebsiteUrl;
