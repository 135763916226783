import { applyMiddleware, createStore } from 'redux';
import thunk from 'redux-thunk';
import reducers from '../reducers';

const bindMiddleware = middleware => {
    return applyMiddleware(...middleware);
};

function configureStore(initialState = {}) {
    const store = createStore(reducers(), initialState, bindMiddleware([thunk]));
    return store;
}
export default configureStore;