import React, { useEffect, useState } from 'react';
import { useParams, useLocation, useHistory } from "react-router-dom";
import { Card, Grid, Typography } from "@material-ui/core";
import { rootUrl } from "../../../api-handling/rootUrl";
import { axios } from "../../../api-handling/http-service";
import { scroller } from "react-scroll";
import { useDispatch, useSelector } from 'react-redux';
import { setProfession } from "../../../redux/actions/profession"
function ToSeoUrl(url) {
    // make the url lowercase         
    var encodedUrl = url.toString().toLowerCase();
    // replace & with and           
    encodedUrl = encodedUrl.split(/\&+/).join("-and-")
    // remove invalid characters 
    encodedUrl = encodedUrl.split(/[^a-z0-9]/).join("-");
    // remove duplicates 
    encodedUrl = encodedUrl.split(/-+/).join("-");
    // trim leading & trailing characters 
    encodedUrl = encodedUrl.trim('-');
    return encodedUrl;
}
const CategoriesListView = () => {
    const dispatch = useDispatch();
    let [groupedCategories, setGroupedCategories] = useState([]);
    // const alphabets = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'X', 'Y', 'Z']
    const history = useHistory();
    const location = useLocation();
    let urls = location.pathname.split("/");
    useEffect(() => {
        getProfessions()
    }, [])
    let scrollToSection = (scrollId) => {
        scroller.scrollTo(scrollId, {
            duration: 800,
            delay: 0,
            smooth: "easeInOutQuart",
        });
    };

    function navigate(datum) {
        dispatch(setProfession(datum))
        var catname = ToSeoUrl(datum.name)
        history.push("/" + urls[1] + "/" + urls[2] + "/" + catname + "/" + urls[3])
        window.scrollTo(0, 0)
    }

    let getProfessions = async () => {
        await axios
            .get(`${rootUrl}/get-all-profession`)
            .then(res => {
                let data = res?.data?.data.reduce((r, e) => {
                    // get first letter of name of current element
                    let group = e.name[0];
                    // if there is no property in accumulator with this letter create it
                    if (!r[group]) r[group] = { group, children: [e] }
                    // if there is push current element to children array for that letter
                    else r[group].children.push(e);
                    // return accumulator
                    return r;
                }, {})
                let result = Object.values(data)

                console.log(result)
                setGroupedCategories(result);
            })
            .catch(error => {
                console.log('Error: ', error);
            })
            .then(res => {
            });
    };

    return (
        <div className={'col-xs-12 col-md-12 slp-main-content'} style={{ paddingLeft: '4%', paddingRight: '4%' }}>
            <Card className="p-6" style={{ padding: '2%' }}>
                <Typography variant="h5" className="mb-2">Categories</Typography>
                <div className=" flex-row justify-between align-content-center">
                    {groupedCategories.map((item, index) => {
                        return <span key={index} onClick={() => scrollToSection(item.group)}
                            className="sm:hover:underline cursor-pointer font-weight-bolder text-primary" style={{ margin: '0% 4% 0% 0%' }}>{item.group}</span>
                    })}
                </div>

                <div className="flex-col">
                    {groupedCategories.map((item, index) => {
                        return <div className={item.group} key={index} style={{ marginTop: '5vh' }}>
                            <Typography variant="h4" className="mb-4" >{item.group}</Typography>
                            <Grid container spacing={4}>
                                {item.children.map((datum, ind) => {
                                    return <Grid item key={ind} xs={12} sm={6} md={4} lg={3}
                                        onClick={() => navigate(datum)}
                                        className="text-primary sm:hover:underline cursor-pointer">
                                        {datum.name}
                                    </Grid>
                                })}
                            </Grid>
                        </div>
                    })}
                </div>
            </Card>
        </div>
    )
}

export default CategoriesListView
