import {
    SET_ALL_LOCATION,
    SET_ALL_STATE_IN, SET_ALL_DISTRICTS_IN, SET_ALL_LOCAL_LOCATION_IN,
    SET_ALL_REGION_AU, SET_ALL_LOCAL_LOCATION_AU,
    SET_ALL_REGION_NZ, SET_ALL_LOCAL_LOCATION_NZ,
    SET_IP_INFO, SET_IFRAME_URL,
} from '../constants/locationActionTypes'
import { rootUrl } from "../../api-handling/rootUrl";
import { axios } from "../../api-handling/http-service";

export const setLocations = data => {
    return dispatch => {
        dispatch({
            type: SET_ALL_LOCATION,
            payload: data,
        });
    };
};

export const setIpInfo = data => {
    return dispatch => {
        dispatch(setIfameUrl(data))
        dispatch({
            type: SET_IP_INFO,
            payload: data,
        });
    };
};

export const setIfameUrl = data => {
    return dispatch => {
        let url;
        if (data.country === "IN") {
            url = "https://tradingseek.in";
        } else if (data.country === "AU") {
            url = "https://tradingseek.com.au";
        } else if (data.country === "NZ") {
            url = "https://tradingseek.co.nz";
        } else {
            url = "https://tradingseek.com.au";
        }
        dispatch({
            type: SET_IFRAME_URL,
            payload: url,
        });
    };
};

export const getIpInfo = () => {
    return dispatch => {
        axios
            .get('https://ipinfo.io?token=aad467b3abcf85')
            .then(response => {
                dispatch(setIpInfo(response.data))
            })
            .catch(error => {
                console.log(error.message)
            });
    }
}

export const getAllLocations = (country) => {
    return dispatch => {
        // axios.get(`${rootUrl}/get-all-locations/${country}`)
        //     .then(({ data }) => {
        //         dispatch(setLocations(data));
        //     }).catch(function (error) {
        //         dispatch(setLocations([]));
        //     })

        // if (country === "in") {
        //     import('../../constants/PostcodeIn.json')
        //         .then((module) => {
        //             dispatch(setLocations(module.default))
        //         }).catch((e) => {
        //             dispatch(setLocations([]))
        //         });
        // } else if (country === "au") {
        //     import('../../constants/PostcodeAu.json')
        //         .then((module) => {
        //             dispatch(setLocations(module.default))
        //         }).catch((e) => {
        //             dispatch(setLocations([]))
        //         });
        // } else if (country === "nz") {
        //     import('../../constants/PostcodeNz.json')
        //         .then((module) => {
        //             dispatch(setLocations(module.default))
        //         }).catch((e) => {
        //             dispatch(setLocations([]))
        //         });
        // } else if (country === "in") {
        //     import('../../constants/PostcodeIn.json')
        //         .then((module) => {
        //             dispatch(setLocations(module.default))
        //         }).catch((e) => {
        //             dispatch(setLocations([]))
        //         });
        // }
    }
}

export const getAllStateIn = () => {
    return dispatch => {
        axios.post(`${rootUrl}/in/get-all-state`)
            .then(({ data }) => {
                dispatch({
                    type: SET_ALL_STATE_IN,
                    payload: data.data,
                });
            }).catch(function (error) {
                dispatch({
                    type: SET_ALL_STATE_IN,
                    payload: [],
                });
            })
    }
}

export const getAllDistrictsIn = (state) => {
    return dispatch => {
        axios.post(`${rootUrl}/in/get-all-district`, {
            state
        }).then(({ data }) => {
            dispatch({
                type: SET_ALL_DISTRICTS_IN,
                payload: data.data,
            });
        }).catch(function (error) {
            dispatch({
                type: SET_ALL_DISTRICTS_IN,
                payload: [],
            });
        })
    }
}

export const getAllLocallocationIn = (state, districts) => {
    return dispatch => {
        axios.post(`${rootUrl}/in/get-all-local-location`, {
            state,
            districts
        }).then(({ data }) => {
            dispatch({
                type: SET_ALL_LOCAL_LOCATION_IN,
                payload: data.data,
            });
        }).catch(function (error) {
            dispatch({
                type: SET_ALL_LOCAL_LOCATION_IN,
                payload: [],
            });
        })
    }
}


export const getAllRegionAu = () => {
    return dispatch => {
        axios.post(`${rootUrl}/au/get-all-region`)
            .then(({ data }) => {
                dispatch({
                    type: SET_ALL_REGION_AU,
                    payload: data.data,
                });
            }).catch(function (error) {
                dispatch({
                    type: SET_ALL_REGION_AU,
                    payload: [],
                });
            })
    }
}

export const getAllLocallocationAu = (region) => {
    return dispatch => {
        axios.post(`${rootUrl}/au/get-all-local-location`, {
            region
        }).then(({ data }) => {
            dispatch({
                type: SET_ALL_LOCAL_LOCATION_AU,
                payload: data.data,
            });
        }).catch(function (error) {
            dispatch({
                type: SET_ALL_LOCAL_LOCATION_AU,
                payload: [],
            });
        })
    }
}


export const getAllRegionNz = () => {
    return dispatch => {
        axios.post(`${rootUrl}/nz/get-all-region`)
            .then(({ data }) => {
                dispatch({
                    type: SET_ALL_REGION_NZ,
                    payload: data.data,
                });
            }).catch(function (error) {
                dispatch({
                    type: SET_ALL_REGION_NZ,
                    payload: [],
                });
            })
    }
}

export const getAllLocallocationNz = (region) => {
    return dispatch => {
        axios.post(`${rootUrl}/nz/get-all-local-location`, {
            region
        }).then(({ data }) => {
            dispatch({
                type: SET_ALL_LOCAL_LOCATION_NZ,
                payload: data.data,
            });
        }).catch(function (error) {
            dispatch({
                type: SET_ALL_LOCAL_LOCATION_NZ,
                payload: [],
            });
        })
    }
}


//search indian location 
export const searchTerritoryIn = (state) => {
    axios.post(`${rootUrl}/in/search-state`, {
        state
    }).then(({ data }) => {
        return data;
    }).catch(function (error) {
        return null;
    })
}

export const searchDistrictIn = (districts) => {
    axios.post(`${rootUrl}/in/search-districts`, {
        districts
    }).then(({ data }) => {
        return data;
    }).catch(function (error) {
        return null;
    })
}

export const searchLocalityIn = (area) => {
    axios.post(`${rootUrl}/in/search-locality`, {
        area
    }).then(({ data }) => {
        return data;
    }).catch(function (error) {
        return null;
    })
}

export const searchPostcodeIn = (pincode) => {
    axios.post(`${rootUrl}/in/search-postcode`, {
        pincode
    }).then(({ data }) => {
        return data;
    }).catch(function (error) {
        return null;
    })
}



//search aus location 
export const searchTerritoryAu = (state) => {
    return dispatch => {
        axios.post(`${rootUrl}/au/search-state`, {
            state
        }).then(({ data }) => {
            return data;
        }).catch(function (error) {
            return null;
        })
    }
}

export const searchLocalityAu = (area) => {
    axios.post(`${rootUrl}/au/search-locality`, {
        area
    }).then(({ data }) => {
        return data;
    }).catch(function (error) {
        return null;
    })
}

export const searchPostcodeAu = (pincode) => {
    axios.post(`${rootUrl}/au/search-postcode`, {
        pincode
    }).then(({ data }) => {
        return data;
    }).catch(function (error) {
        return null;
    })
}


//search nzl location 
export const searchTerritoryNz = (state) => {
    axios.post(`${rootUrl}/nz/search-state`, {
        state
    }).then(({ data }) => {
        return data;
    }).catch(function (error) {
        return null;
    })
}

export const searchLocalityNz = (area) => {
    axios.post(`${rootUrl}/nz/search-locality`, {
        area
    }).then(({ data }) => {
        return data;
    }).catch(function (error) {
        return null;
    })
}

export const searchPostcodeNz = (pincode) => {
    axios.post(`${rootUrl}/nz/search-postcode`, {
        pincode
    }).then(({ data }) => {
        return data;
    }).catch(function (error) {
        return null;
    })
}


export const getPolygonCodeAu = (searchText) => {
    return async (dispatch) => {
        try {
            const response = await axios.post(`${rootUrl}/au/search-polygon-code`, {
                searchText
            });
            return response.data.allCodes;
        } catch (error) {
            console.error("Error in getPolygonCodeIn:", error);
            return null;
        }
    };
};
export const getPolygonCodeIn = (searchText) => {
    return async (dispatch) => {
        try {
            const response = await axios.post(`${rootUrl}/in/search-polygon-code`, {
                searchText
            });
            return response.data.allCodes;
        } catch (error) {
            console.error("Error in getPolygonCodeIn:", error);
            return null;
        }
    };
};
export const getPolygonCodeNz = (searchText) => {
    return async (dispatch) => {
        try {
            const response = await axios.post(`${rootUrl}/nz/search-polygon-code`, {
                searchText
            });
            return response.data.allCodes;
        } catch (error) {
            console.error("Error in getPolygonCodeIn:", error);
            return null;
        }
    };
};
