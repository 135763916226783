import React, { useState, useEffect } from 'react';
import { useParams, useLocation, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import MainLoader from '../pages/Spinners/mainLoader/mainLoader';
// import HelmetMetaData from './HelmetForSeo'
import { ToSeoUrl } from "../../constants/commonFunctions"
import api from "axios";
import { rootUrl } from "../../api-handling/rootUrl";
import { axios } from "../../api-handling/http-service";
import { setIpInfo } from "../../redux/actions/location"
import { setProfession } from "../../redux/actions/profession"
const RedirectPage = () => {
    const dispatch = useDispatch()
    const { servicedetails } = useSelector(({ profession }) => profession);

    const [locationDetails, setlocationDetails] = useState(null);
    useEffect(() => {
        //alert(1)
        fetchProfession();
        fetchLocationDetails();
    }, [])
    useEffect(() => {
        if (locationDetails && servicedetails) {
            // console.log('locationDetails==', locationDetails)
            urlRedirect(locationDetails);
        }
    }, [locationDetails, servicedetails])
    const history = useHistory();
    const location = useLocation();
    let urls = location.pathname.split("/");
    //console.log(location.pathname);
    function generateRandom(maxLimit = 100) {
        let rand = Math.random() * maxLimit;
        rand = Math.floor(rand);
        console.log(rand);
        return rand;
    }
    const fetchProfession = () => {
        axios.get(`${rootUrl}/get-all-profession`)
            .then(response => {
                let rescount = response.data.length;
                let rand = generateRandom(rescount)
                // console.log("response =", response.data.data[rand]);
                dispatch(setProfession(response.data.data[rand]))
            }).catch(error => {
                console.log(error.message)
            })
    }

    // const fetchLocationDetails = () => {
    //     //alert(1)
    //     api.get(`https://geolocation-db.com/json/`)
    //         .then(response => {
    //             console.log("response1=", response.data)
    //             setlocationDetails(response.data)
    //         }).catch(error => {
    //             console.log(error.message)
    //         })
    // }
    const fetchLocationDetails = () => {
        api
            .get('https://ipinfo.io?token=aad467b3abcf85')
            .then(response => {
                console.log("response1=", response.data)
                setlocationDetails(response.data)
                dispatch(setIpInfo(response.data))
            })
            .catch(error => {
                console.log(error.message)
            });
    }


    let urlRedirect = (locationDetails) => {
        //let countrycode = locationDetails?.country_code
        let countrycode = locationDetails?.country
        if (countrycode == "IN") {
            history.push(`/local/in/` + ToSeoUrl(servicedetails?.name) + `/` + ToSeoUrl(locationDetails?.region))
        } else if (countrycode == "AU") {
            history.push(`/local/au/` + ToSeoUrl(servicedetails?.name) + `/` + ToSeoUrl(locationDetails?.region) + `/` + ToSeoUrl(locationDetails?.city) + `+` + locationDetails?.postal)
        } else if (countrycode == "NZ") {
            history.push(`/local/nz/` + ToSeoUrl(servicedetails?.name) + `/` + ToSeoUrl(locationDetails?.region) + `/` + ToSeoUrl(locationDetails?.city) + `+` + locationDetails?.postal)
        }
        else {
            history.push(`/local`)
        }
    }

    return (
        <div>
            {/* <HelmetMetaData /> */}
            <MainLoader />
        </div>
    )
}
export default RedirectPage;
