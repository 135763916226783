import React, { Component, useEffect, useState } from 'react';
import GoogleMapReact from 'google-map-react';
import Geocode from "react-geocode";
import { useLocation } from 'react-router-dom';

const Map = () => {

  let [geoLatitude, setGeoLatitude] = useState(20.5937);
  let [geoLongitude, setGeoLongitude] = useState(78.9629);

  const location = useLocation();
  let urls = location.pathname.split("/");

  var locationFromUrl = urls.slice(-1)[0];
  useEffect(() => {
    if (locationFromUrl == "local") {
      locationFromUrl = "india";
    }
    getLatLng(locationFromUrl);
  }, [])

  Geocode.setApiKey("AIzaSyCbbBBatSyFqsKVvVCaK22VVtHWsXFESls");
  Geocode.setLanguage("en");
  Geocode.setRegion("es");
  Geocode.setLocationType("ROOFTOP");
  Geocode.enableDebug();
  const getLatLng = (locationFromUrl) => {
    Geocode.fromAddress(locationFromUrl).then(
      (response) => {
        const { lat, lng } = response.results[0].geometry.location;
        setGeoLatitude(lat)
        setGeoLongitude(lng)
      },
      (error) => {
        console.error(error);
      }
    );
  }

  return (
    // Important! Always set the container height explicitly
    <div style={{ height: '65vh', width: '100%', marginTop: '15vh' }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: 'AIzaSyCbbBBatSyFqsKVvVCaK22VVtHWsXFESls' }}
        defaultCenter={{ lat: geoLatitude, lng: geoLongitude }}
        //defaultCenter={{lat: 24.886, lng: -70.268 }}
        defaultZoom={4}
        yesIWantToUseGoogleMapApiInternals //this is important!
      >

      </GoogleMapReact>
    </div>
  );
}

export default Map;