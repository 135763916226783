import { rootUrl } from "./rootUrl";
let http = require('axios');

let tokenPromise = null; // Store the token promise

const api = http.create();

// Function to fetch access token
async function getAccessToken() {
    try {
        const response = await http.post(`${rootUrl}/get-token`, {
            isTrusted: true
        });
        return response.data.access_token;
    } catch (err) {
        console.error(err);
        throw err;
    }
}

// Function to ensure token is available
async function ensureToken() {
    if (!tokenPromise) {
        tokenPromise = getAccessToken();
    }
    return tokenPromise;
}

// Add request interceptor
api.interceptors.request.use(async (config) => {
    const token = await ensureToken();
    config.headers.Authorization = 'Bearer ' + token;
    return config;
});

// Add response interceptor
api.interceptors.response.use(
    (response) => response,
    async (error) => {
        const originalRequest = error.config;
        if (error.response.status === 401 && !originalRequest._retry) {
            // Reset token promise and retry the request
            tokenPromise = null;
            return api(originalRequest);
        }
        return Promise.reject(error);
    }
);

export const axios = api;
