import { rootUrl } from "./rootUrl";
import { axios } from "./http-service";

function ToSeoUrl(url) {
    // make the url lowercase         
    var encodedUrl = url?.toString().toLowerCase();
    // replace & with and           
    encodedUrl = encodedUrl?.split(/\&+/).join("-and-")
    // remove invalid characters 
    encodedUrl = encodedUrl?.split(/[^a-z0-9]/).join("-");
    // remove duplicates 
    encodedUrl = encodedUrl?.split(/-+/).join("-");
    // trim leading & trailing characters 
    encodedUrl = encodedUrl?.trim('-');
    return encodedUrl;
}

const searchPostcode = async (pincode) => {
    let matches;
    await axios.post(`${rootUrl}/au/search-postcode`, {
        pincode
    }).then(({ data }) => {
        matches = data.data;
    }).catch(function (error) {
        console.log('error=', error.message)
    })
    return matches;
}

const searchLocality = async (area) => {
    let matches;
    await axios.post(`${rootUrl}/au/search-locality`, {
        area
    }).then(({ data }) => {
        matches = data.data;
    }).catch(function (error) {
        console.log('error=', error.message)
    })
    return matches;
}

const searchTerritory = async (state) => {
    let matches;
    await axios.post(`${rootUrl}/au/search-state`, {
        state
    }).then(({ data }) => {
        matches = data.data;
    }).catch(function (error) {
        console.log('error=', error.message)
    })
    return matches;
}


export { searchPostcode, searchLocality, searchTerritory }
