import React from 'react';
import { useHistory } from "react-router-dom";
import Map from './mapLanding';

function ToSeoUrl(url) {
    var encodedUrl = url?.toString().toLowerCase();
    encodedUrl = encodedUrl?.split(/\&+/).join("-and-")
    encodedUrl = encodedUrl?.split(/[^a-z0-9]/).join("-");
    encodedUrl = encodedUrl?.split(/-+/).join("-");
    encodedUrl = encodedUrl?.trim('-');
    return encodedUrl;
}
const groupBy = (array, key) => {
    var data = [];
    console.log(array.length)
    array.map((result, indx) => {
        data.push(result[key])
    })
    data = [...new Set(data)]
    return data.sort();
};
let LocationData = [{ "country": "India", "alias": "in" }, { "country": "Australia", "alias": "au" }, { "country": "New Zealand", "alias": "nz" }];

const LocalLeft = ({ servicedetails, locality, territory, state }) => {
    const history = useHistory();
    return (
        <div className={'col-xs-12  col-sm-12 col-md-12 col-lg-8 slp-main-content'} style={{ textAlign: 'center' }}>
            <h2>Select Your Country </h2>
            <div className="slp-links-panel text-center">
                {
                    LocationData?.map((location, index) => {
                        return (
                            <a onClick={() => { history.push("/local/" + ToSeoUrl(location.alias)) }} className="btn btn-inline btn-primary-mod btn-lg">
                                {location.country}
                            </a>
                        );
                    })
                }
            </div>
            <div style={{ textAlign: 'justify', marginTop: '10vh' }}>
                <h3>Content Heading Gose Here</h3>

                <p>
                    Need Cleaners in West End?  Call TradingSeek West End for all your domestic and commercial
                    cleaning services in West End.  Our professional team are available 7 days a week; book a free
                    quote online or call 123 456.
                </p>
            </div>

        </div>
    );
}
export default LocalLeft;