import React, { useEffect, useState } from 'react';
import HorizontalSplitIcon from '@material-ui/icons/HorizontalSplit';
import CloseIcon from '@material-ui/icons/Close';
import { useDispatch, useSelector } from 'react-redux';
import { setIpInfo, getIpInfo } from "../../../redux/actions/location"

import {
  NavLink
} from './NavbarElements';
import TodayIcon from '@material-ui/icons/Today';

const Navbar = () => {
  const dispatch = useDispatch()
  const { iframeUrl } = useSelector(({ location }) => location);
  useEffect(() => {
    if (iframeUrl === "") {
      dispatch(getIpInfo())
    }
  }, [iframeUrl])

  const [click, setClick] = React.useState(false);

  const handleClick = () => setClick(!click);
  const Close = () => setClick(false);

  return (
    <div className={"box-shadow"} style={{ marginBottom: '20px' }}>
      <div className={click ? "main-container" : ""} onClick={() => Close()} />
      <nav className="navbar" onClick={(e) => e.stopPropagation()} >
        <div className="nav-container">
          <NavLink exact to="/" className="nav-logo">
            <img className={'logo'} src={`${iframeUrl}/images/Tradingseek.svg`} />
            <p style={{ fontSize: '22px' }}>TradingSeek</p>
          </NavLink>

          <ul className={click ? "nav-menu active" : "nav-menu"}>
            <li className="nav-item">
              <NavLink
                exact
                to="/"
                activeClassName="active"
                className="nav-links"
                onClick={click ? handleClick : null}
              >
                Home
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                to={{ pathname: `${iframeUrl}/business-directory` }}
                target="_blank"
                rel="noopener noreferrer"
                activeClassName="active"
                className="nav-links"
              >
                Business Directory
              </NavLink>

            </li>
            <li className="nav-item">
              <NavLink
                to={{ pathname: `${iframeUrl}/about` }}
                target="_blank"
                rel="noopener noreferrer"
                activeClassName="active"
                className="nav-links"
              >
                About
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                to={{ pathname: `${iframeUrl}/contact` }}
                target="_blank"
                rel="noopener noreferrer"
                activeClassName="active"
                className="nav-links"
              >
                Contact
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                to={{ pathname: `${iframeUrl}/how-it-works` }}
                target="_blank"
                rel="noopener noreferrer"
                activeClassName="active"
                className="nav-links"
              >
                How it Works
              </NavLink>
            </li>
          </ul>
          <NavLink
            to={{ pathname: `${iframeUrl}/home` }}
            target="_blank"
            rel="noopener noreferrer"
            activeClassName="active"
            className="nav-links booknow"
          >
            <span className="circle-border">
              <TodayIcon />
            </span>
            <span className="text-enclosure">
              <span className="slogan">Request a Free Quote</span>
              <span className="action">Book Online</span>
            </span>
          </NavLink>

          <div className="nav-icon" onClick={handleClick}>
            {click ? <CloseIcon /> : <HorizontalSplitIcon />}
          </div>
        </div>
      </nav>
    </ div>
  );
};

export default Navbar;