import React, { useState, useEffect } from 'react';
import { useParams, useLocation, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import Navbar from './header';
import Banner from './banner';
import CategoriesListView from './CategoriesListView'
import Right from './right_content'
import { axios } from "../../../api-handling/http-service";
import { rootUrl } from "../../../api-handling/rootUrl";
import './index.css';
import HelmetMetaData from './HelmetForSeo'
import Footer from './footer'
import 'bootstrap/dist/css/bootstrap.min.css';
import { searchPostcode, searchLocality, searchTerritory } from "../../../api-handling/fetchLocationsIn";

const AllServicesIn = () => {
    var { area } = useParams();
    let [servicedetails, setService] = useState(null);
    let [postcode, setPostcode] = useState(null);
    let [locality, setLocality] = useState(null);
    let [territory, setTerritory] = useState(null);
    const dispatch = useDispatch();

    useEffect(() => {
        urlRedirect(territory);
    }, [area, territory]);
    useEffect(() => {
        urlValidate(area);
    }, [])
    const history = useHistory();
    const location = useLocation();
    let urls = location.pathname.split("/");
    //console.log(location.pathname);


    let urlValidate = async (area) => {
        await searchTerritory(area).then(res => {
            //console.log(area)
            // console.log("area=======",area)
            // console.log("res=======",res)
            if (res.length) {
                var loc = res[0];
                setTerritory(loc.state);
            } else {
                setTerritory(null);
            }

        });
    }

    let urlRedirect = (territory) => {
        //console.log("territory=",territory)
        if (territory === "") {
            history.push("/" + urls[1] + "/in")
        }
    }

    return (
        <div>
            <HelmetMetaData servicedetails={servicedetails} locality={locality} territory={territory} area={area} />
            <Navbar />
            <Banner servicedetails={servicedetails} locality={locality} territory={territory} area={area} />
            <div className={'row'}>
                <CategoriesListView />
            </div>
            <Footer />
        </div>
    );
}
export default AllServicesIn;
