import React, { Component, useEffect, useState, Suspense } from 'react';
import GoogleMapReact from 'google-map-react';
import { useDispatch, useSelector } from 'react-redux';
import Geocode from "react-geocode";
import { useLocation } from 'react-router-dom';
import { getPolygonCodeIn } from "../../../redux/actions/location"

const Map = () => {
  const dispatch = useDispatch()

  const searchlatlng = async (searchText) => {
    try {
      const allPolygonCodes = await dispatch(getPolygonCodeIn(searchText));
      return allPolygonCodes;
    } catch (error) {
      console.error("Error in searchlatlng:", error);
      return null;
    }
  };


  let [geoLatitude, setGeoLatitude] = useState(20.5937);
  let [geoLongitude, setGeoLongitude] = useState(78.9629);
  let [diffZoom, setdiffZoom] = useState(5);
  const [codes, setCodes] = useState([]);
  const location = useLocation();
  let urls = location.pathname.split("/");
  let locOnMap = urls[4];
  //console.log(locOnMap);
  var locationFromUrl = urls.slice(-1)[0];

  useEffect(() => {
    getLatLng(locationFromUrl);
  }, [])




  useEffect(() => {
    if (codes.length == 0) {
      searchlatlng(locOnMap).then(val => setCodes(val))
    }
  }, [])
  //console.log("----",codes)
  // console.log("urls=",urls)
  // console.log("locationFromUrl=",locationFromUrl)
  //console.log("codes=",codes)
  Geocode.setApiKey("AIzaSyCbbBBatSyFqsKVvVCaK22VVtHWsXFESls");
  Geocode.setLanguage("en");
  Geocode.setRegion("es");
  Geocode.setLocationType("ROOFTOP");
  Geocode.enableDebug();
  const getLatLng = (locationFromUrl) => {
    if (locationFromUrl != "india") {
      locationFromUrl = locationFromUrl + " india";
    }
    Geocode.fromAddress(locationFromUrl).then(
      (response) => {
        const { lat, lng } = response.results[0].geometry.location;
        setGeoLatitude(lat)
        setGeoLongitude(lng)
        if (locationFromUrl != "india") {
          setdiffZoom(7)
        }
      },
      (error) => {
        console.error(error);
      }
    );
  }
  if (locationFromUrl == "local") {
    locationFromUrl = "india";
  }
  //console.log("geoLatitude=",geoLatitude)
  //console.log("geoLongitude=",geoLongitude)


  const handleApiLoaded = (map, maps) => {

    var bermudaTriangle = new maps.Polygon({
      paths: codes,
      strokeColor: "#1a349000",
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: "blue",
      fillOpacity: 0.35
    });
    if (codes.length > 0) {
      bermudaTriangle.setMap(map);
    }
  }
  return (
    // Important! Always set the container height explicitly
    <div style={{ height: '65vh', width: '100%', marginTop: '15vh' }}>
      {codes?.length > 0 &&
        // <Suspense fallback={'loading...'}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: 'AIzaSyCbbBBatSyFqsKVvVCaK22VVtHWsXFESls' }}
          defaultCenter={{ lat: geoLatitude, lng: geoLongitude }}
          center={{ lat: geoLatitude, lng: geoLongitude }}
          //defaultCenter={{lat: 24.886, lng: -70.268 }}
          defaultZoom={diffZoom}
          yesIWantToUseGoogleMapApiInternals //this is important!
          onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
        >

        </GoogleMapReact>
        // </Suspense> 
      }
    </div>
  );
}

export default Map;