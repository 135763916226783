import React from 'react';
import { Redirect, Router, Route, Switch, BrowserRouter } from 'react-router-dom';
import { createBrowserHistory } from 'history';

import MainLoader from './pages/Spinners/mainLoader/mainLoader';

import SeoPageAu from './pages/seoPagesAu/index';
import LocalAu from './pages/seoPagesAu/local';
import LandingAu from './pages/seoPagesAu/landing';
import AllServicesAu from './pages/seoPagesAu/all_services';

// const SeoPageAu = React.lazy(() => import("./pages/seoPagesAu/index"))
// const LocalAu = React.lazy(() => import("./pages/seoPagesAu/local"))
// const LandingAu = React.lazy(() => import("./pages/seoPagesAu/landing"))
// const AllServicesAu = React.lazy(() => import("./pages/seoPagesAu/all_services"))

import SeoPageIn from './pages/seoPagesIn/index';
import LocalIn from './pages/seoPagesIn/local';
import DistrictIn from './pages/seoPagesIn/district';
import LandingIn from './pages/seoPagesIn/landing';
import AllServicesIn from './pages/seoPagesIn/all_services';

// const SeoPageIn = React.lazy(() => import("./pages/seoPagesIn/index"))
// const LocalIn = React.lazy(() => import("./pages/seoPagesIn/local"))
// const DistrictIn = React.lazy(() => import("./pages/seoPagesIn/district"))
// const LandingIn = React.lazy(() => import("./pages/seoPagesIn/landing"))
// const AllServicesIn = React.lazy(() => import("./pages/seoPagesIn/all_services"))

import SeoPageNz from './pages/seoPagesNz/index';
import LocalNz from './pages/seoPagesNz/local';
import LandingNz from './pages/seoPagesNz/landing';
import AllServicesNz from './pages/seoPagesNz/all_services';

// const SeoPageNz = React.lazy(() => import("./pages/seoPagesNz/index"))
// const LocalNz = React.lazy(() => import("./pages/seoPagesNz/local"))
// const LandingNz = React.lazy(() => import("./pages/seoPagesNz/landing"))
// const AllServicesNz = React.lazy(() => import("./pages/seoPagesNz/all_services"))

import AllCountry from './pages/seoPagesNz/allCountry';
import RedirectPage from './pages/index';

// const AllCountry = React.lazy(() => import('./pages/seoPagesNz/allCountry'));
// const RedirectPage = React.lazy(() => import('./pages/index'));
// const history = createBrowserHistory();

const Routes = () => {
    return (
        <React.Fragment>
            {/* <React.Suspense fallback={<MainLoader />}> */}

            <Switch>
                {/* AU */}

                <Route exact path="/local/au/:service/:area/:locationss" component={LocalAu} />
                <Route exact path="/local/au/:service/:area" component={SeoPageAu} />
                <Route exact path="/local/au/:area" component={AllServicesAu} />
                <Route exact path="/local/au" component={LandingAu} />

                {/* IN */}
                <Route exact path="/local/in/:service/:area/:district/:locationss" component={LocalIn} />
                <Route exact path="/local/in/:service/:area/:district" component={DistrictIn} />
                <Route exact path="/local/in/:service/:area" component={SeoPageIn} />
                <Route exact path="/local/in/:area" component={AllServicesIn} />
                <Route exact path="/local/in" component={LandingIn} />

                {/* NZ */}
                <Route exact path="/local/nz/:service/:area/:locationss" component={LocalNz} />
                <Route exact path="/local/nz/:service/:area" component={SeoPageNz} />
                <Route exact path="/local/nz/:area" component={AllServicesNz} />
                <Route exact path="/local/nz" component={LandingNz} />

                <Route exact path="/local" component={AllCountry} />
                <Route exact path="/" component={RedirectPage} />
                {/* <Route exact path="/" component={() => <div>Test </div>} /> */}

            </Switch>
            {/* </React.Suspense> */}
        </React.Fragment>
    );
};
export default Routes;

