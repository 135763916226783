import React from 'react';
import { Switch, BrowserRouter, Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';

import { Provider } from 'react-redux';

import Routes from './routes';
import configureStore from './redux/store';

export const store = configureStore();
// const history = createBrowserHistory();
function App() {
  return (
    <Provider store={store}>
      <Switch>
        <Routes />
      </Switch>
    </Provider>
  );
}

export default App;
