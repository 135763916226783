import {
    SET_ALL_PROFESSIONS, SET_PROFESSION,
} from '../constants/professionActionTypes'
import { rootUrl } from "../../api-handling/rootUrl";
import { axios } from "../../api-handling/http-service";

export const setProfessions = data => {
    return dispatch => {
        dispatch({
            type: SET_ALL_PROFESSIONS,
            payload: data,
        });
    };
};

export const setProfession = data => {
    return dispatch => {
        dispatch({
            type: SET_PROFESSION,
            payload: data,
        });
    };
};

export const getAllProfessions = () => {
    return dispatch => {
        axios.get(`${rootUrl}/get-all-profession`)
            .then(res => {
                let data = res?.data?.data.reduce((r, e) => {
                    // get first letter of name of current element
                    let group = e.name[0];
                    // if there is no property in accumulator with this letter create it
                    if (!r[group]) r[group] = { group, children: [e] }
                    // if there is push current element to children array for that letter
                    else r[group].children.push(e);
                    // return accumulator
                    return r;
                }, {})
                let result = Object.values(data)
                dispatch(setProfessions(result));
            })
            .catch(function (error) {
                dispatch({
                    type: SET_ALL_PROFESSIONS,
                    payload: [],
                });
            })
    }
}

export const getProfessionByName = (name) => {
    return dispatch => {
        axios.get(`${rootUrl}/get-profession-using-name/${name}`)
            .then(res => {
                dispatch(setProfession(res.data))
            })
            .catch(function (error) {
                dispatch({
                    type: SET_PROFESSION,
                    payload: {},
                });
            })
    }
}