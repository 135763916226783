import React, { useEffect, useState } from 'react';
import { useParams, useLocation, useHistory } from "react-router-dom";
import { Card, Grid, Typography } from "@material-ui/core";
import { useDispatch, useSelector } from 'react-redux';
import { getAllLocallocationIn } from "../../../redux/actions/location"
import { scroller } from "react-scroll";

function ToSeoUrl(url) {
    if (url != "") {
        var encodedUrl = url?.toString().toLowerCase();
        encodedUrl = encodedUrl?.split(/\&+/).join("-and-")
        encodedUrl = encodedUrl?.split(/[^a-z0-9]/).join("-");
        encodedUrl = encodedUrl?.split(/-+/).join("-");
        encodedUrl = encodedUrl?.trim('-');
        return encodedUrl;
    }
}
const LocationsListView = () => {
    let [groupedCategories, setGroupedCategories] = useState([]);
    // const alphabets = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'X', 'Y', 'Z']
    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();
    const { localLocationIn } = useSelector(({ location }) => location);


    let urls = location.pathname.split("/");
    let localLocation = [];
    useEffect(() => {
        if (localLocationIn.length > 0) {
            getProfessions();
        }
    }, [localLocationIn]);
    useEffect(() => {
        dispatch(getAllLocallocationIn(urls[4], urls[5]));
    }, [])
    let scrollToSection = (scrollId) => {
        scroller.scrollTo(scrollId, {
            duration: 800,
            delay: 0,
            smooth: "easeInOutQuart",
        });
    };

    function navigate(region, districts, locality, postcode) {
        var urlregion = ToSeoUrl(region)
        if (locality) {
            var urllocality = ToSeoUrl(locality)
            var urldistricts = ToSeoUrl(districts)
            history.push("/" + urls[1] + "/" + urls[2] + "/" + urls[3] + "/" + urlregion + "/" + urldistricts + "/" + urllocality + "+" + postcode)
        } else {
            history.push("/" + urls[1] + "/" + urls[2] + "/" + urls[3] + "/" + urlregion)
        }
        window.scrollTo(0, 0)
    }

    let getProfessions = async () => {

        let data = localLocationIn.reduce((r, e) => {
            // get first letter of name of current element
            //console.log(r)
            //let group = e.region[0];
            let group = e.state;
            // if there is no property in accumulator with this letter create it
            if (!r[group]) r[group] = { group, children: [e] }
            // if there is push current element to children array for that letter
            else r[group].children.push(e);
            // return accumulator
            return r;
        }, {})
        let result = Object.values(data)
        result.sort(function (a, b) {
            return a.group.localeCompare(b.group);
        })
        //console.log(result)
        setGroupedCategories(result);
    };

    return (
        <div className={'col-xs-12 col-md-12 slp-main-content'}>
            <Card className="p-6" style={{ padding: '2%' }}>
                <Typography variant="h5" className="mb-2">Our Services in </Typography>
                {/* <div className=" flex-row justify-between align-content-center">
                    {groupedCategories.map((item, index) => {
                        return <span key={index} onClick={()=> scrollToSection(item.group)}
                                     className="sm:hover:underline cursor-pointer font-weight-bolder text-primary" style={{margin:'0% 4% 0% 0%'}}>{item.group}</span>
                    })}
                </div> */}

                <div className="flex-col">
                    {/* {groupedCategories && groupedCategories.map((item, index) => {
                        if (ToSeoUrl(item.children[index]?.state) == ToSeoUrl(urls[4])) {
                            debugger
                            item.children.map((item1, index1) => {
                                debugger
                                if (ToSeoUrl(item1.districts) == ToSeoUrl(urls[5])) {
                                    debugger
                                    localLocation.push(item1)
                                }
                            })
                            console.log("localLocation=", localLocation)
                            return <div className={item.group} key={index} style={{ marginTop: '5vh' }}>
                                <Typography variant="h4" className="mb-4 cursor-pointer" onClick={() => navigate(localLocation[0].districts)} >{localLocation[0].districts}</Typography>
                                <Grid container spacing={1}>
                                    {localLocation.map((datum, ind) => {
                                        return <Grid item key={ind} xs={12} sm={6} md={4} lg={3}
                                            onClick={() => navigate(datum.state, datum.districts, datum.area, datum.pincode)}
                                            className="text-primary sm:hover:underline cursor-pointer">
                                            {datum.area}
                                        </Grid>
                                    })}
                                </Grid>
                            </div>
                        }
                    })} */}
                    {groupedCategories && groupedCategories.map((item, index) => {
                        let localLocation = item.children.filter(e => ToSeoUrl(e.districts) === ToSeoUrl(urls[5]));
                        if (localLocation.length > 0) {
                            return (
                                <div className={item.group} key={index} style={{ marginTop: '5vh' }}>
                                    <Typography variant="h4" className="mb-4 cursor-pointer" onClick={() => navigate(localLocation[0].districts)}>
                                        {localLocation[0].districts}
                                    </Typography>
                                    <Grid container spacing={1}>
                                        {localLocation.map((datum, ind) => (
                                            <Grid item key={ind} xs={12} sm={6} md={4} lg={3}
                                                onClick={() => navigate(datum.state, datum.districts, datum.area, datum.pincode)}
                                                className="text-primary sm:hover:underline cursor-pointer">
                                                {datum.area}
                                            </Grid>
                                        ))}
                                    </Grid>
                                </div>
                            );
                        } else {
                            return null;
                        }
                    })}
                </div>
            </Card>
        </div>
    )
}

export default LocationsListView
