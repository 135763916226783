import React from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
import { useParams } from "react-router-dom";
function formatString(input) {
    // Split the string by the dash
    if (!input)
        return

    const parts = input.split('-');

    // Map over each part, capitalize it, and handle the 'and' case
    const formattedParts = parts.map(part => {
        if (part === 'and') {
            return '&'; // Replace 'and' with '&'
        } else {
            // Capitalize the first letter of each part and append the rest of the part
            return part.charAt(0).toUpperCase() + part.slice(1);
        }
    });

    // Join all the parts into a single string without any separators
    return formattedParts.join('');
}

function format(input) {
    if (!input)
        return
    const parts = input.split('+');
    const parts1 = parts[0].split('-');
    const formattedParts = parts1.map(part => {
        if (part === 'and') {
            return 'and';
        } else {
            return part.charAt(0).toUpperCase() + part.slice(1);
        }
    });
    return formattedParts.join(' ');
}
export default function HelmetMetaData(props) {
    var { area, service, district, locationss } = useParams();

    console.log(process.env.REACT_APP_URL);
    let location = useLocation();
    let currentUrl = `https://service.tradingseek.com` + location.pathname;
    let imageText = formatString(service);
    let image = `https://mediatoragetradingseek.s3.amazonaws.com/categoryImages/` + imageText + `.png`
    area = format(area);
    service = format(service);
    district = format(district);
    locationss = format(locationss);
    // console.log('console======', area, service, district, locationss)
    return (
        <Helmet>
            <title>{`${service ? service : ""} ${area ? "services in" : ""} ${!service && !area && !locationss ? "WELCOME TO TRADINGSEEK" : ""} ${locationss && locationss != null ? locationss : '' || district && district != null ? district : '' || area && area != null ? area : ''}`}</title>
            <meta name="description" content={`Need ${service ? service : ""} in ${locationss && locationss != null ? locationss : '' || district && district != null ? district : '' || area && area != null ? area : ''}? Call Trading Seek for all your domestic and commercial ${service ? service : ""} services in ${locationss && locationss != null ? locationss : '' || district && district != null ? district : ''}, ${area && area != null ? area : ''}. Our professional team are available 7 days a week; book a free quote online or call 123456.`} />
            <link rel="canonical" href={`${currentUrl}`} />
            <meta property="og:locale" content="en_US" />
            <meta property="og:type" content="article" />
            <meta property="og:title" content={`${locationss && locationss != null ? locationss : '' || district && district != null ? district : '' || area && area != null ? area : ''} ${service ? service : ""} Services - Ph - 123456 - Trading Seek`} />
            <meta property="og:description" content={`Need ${service ? service : ""} in ${locationss && locationss != null ? locationss : '' || district && district != null ? district : '' || area && area != null ? area : ''}? Call Trading Seek for all your domestic and commercial ${service ? service : ""} services in ${locationss && locationss != null ? locationss : '' || district && district != null ? district : ''}, ${area && area != null ? area : ''}. Our professional team are available 7 days a week; book a free quote online or call 123456.`} />
            <meta property="og:url" content={`${currentUrl}`} />
            <meta property="og:site_name" content="service.tradingseek.com" />
            <meta property="og:image" content={`${image}`} />
            <meta property="og:image:secure_url" content={`${image}`} />
            <meta property="og:image:width" content="300" />
            <meta property="og:image:height" content="200" />
            <meta property="og:image:alt" content={`${locationss && locationss != null ? locationss : '' || district && district != null ? district : '' || area && area != null ? area : ''} ${service ? service : ""} Services`} />
            <meta name="twitter:card" content="summary" />
            <meta name="twitter:description" content={`Need ${service ? service : ""} in ${locationss && locationss != null ? locationss : '' || district && district != null ? district : '' || area && area != null ? area : ''}? Call Trading Seek for all your domestic and commercial ${service ? service : ""} services in ${locationss && locationss != null ? locationss : '' || district && district != null ? district : ''}, ${area && area != null ? area : ''}. Our professional team are available 7 days a week; book a free quote online or call 123456.`} />
            <meta name="twitter:title" content={`${locationss && locationss != null ? locationss : '' || district && district != null ? district : '' || area && area != null ? area : ''} ${service ? service : ""} Services - Ph - 123456 - Trading Seek`} />
            <meta name="twitter:image" content={`${image}`} />

            <script type='application/ld+json'>{`"@context":"https://schema.org ","@type":"Organization","url":"https://service.tradingseek.com/ ","sameAs":[],"@id":"https://service.tradingseek.com/#organization ","name":"Trading Seek","logo":"https://tradingseek.com.au/images/Tradingseek.svg "`}</script>
            <script type='application/ld+json'>
                {`"@context":"https://schema.org ","@type":"BreadcrumbList","itemListElement":[
            {"@type":"ListItem","position":1,"item":{"@id":"https://service.tradingseek.com/ ","name":"Home"}},
            {"@type":"ListItem","position":2,"item":{"@id":"https://service.tradingseek.com/local/au/ ","name":"Local Services"}},
            {"@type":"ListItem","position":3,"item":{"@id":"https://service.tradingseek.com/local/au/cleaner/ ","name":"House Cleaning"}},
            {"@type":"ListItem","position":4,"item":{"@id":"https://service.tradingseek.com/local/au/cleaner/western-australia/ ","name":"Western Australia"}},
            {"@type":"ListItem","position":5,"item":{"@id":"https://service.tradingseek.com/local/au/cleaner/western-australia/lockyer+6330 ","name":"Lockyer Cleaning Services"}}
                ]`}
            </script>

        </Helmet>
    );
}