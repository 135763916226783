import React, { useEffect, useState } from 'react';
import { useParams, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import SosialShare from './sosialicon';
import TwitterIcon from '@material-ui/icons/Twitter';
import MailIcon from '@material-ui/icons/Mail';
import HelmetMetaData from './HelmetForSeo'
import { iframeWebUrl } from "../../../api-handling/rootUrl";
import { setIpInfo, getIpInfo } from "../../../redux/actions/location"

const Right = ({ servicedetails, locality, territory, state }) => {
    const location = useLocation();
    const dispatch = useDispatch()
    const { iframeUrl } = useSelector(({ location }) => location);
    useEffect(() => {
        if (iframeUrl === "") {
            dispatch(getIpInfo())
        }
    }, [iframeUrl])
    return (

        <div className={'col-xs-12  col-sm-12 col-md-12 col-lg-4'}>
            <div style={{ textAlign: 'end', marginRight: '10px' }}>
                <span style={{ fontSize: 'larger' }}>Share this <SosialShare
                    url={location.pathname}
                    quote={servicedetails ? `Bet ${servicedetails.name} Service in ${locality ? locality : territory}` : ``}
                    hashtag={"#tradingseek"}
                />

                </span>
            </div>
            <div className={'call-free-quote'} >
                <a href="tel:131546" style={{ display: 'grid', textDecoration: 'none' }}>
                    <span className={'call-no'}>CALL 123 456</span>
                    <span className={'call-text'} >for FREE Quote Request</span>
                </a>
            </div>
            <div className={'call-free-quote'}>
                <a className={'cursor-pointer'} style={{ display: 'grid', textDecoration: 'none' }}
                    onClick={() => {
                        window.open(`${iframeUrl}/home`)
                    }}
                >
                    <span className={'call-no'}>BOOK ONLINE NOW</span>
                    <span className={'call-text'} >for Request a Free Quote</span>
                </a>
            </div>
            {!servicedetails && !territory && !locality && <div style={{ overflow: 'hidden', margin: '15px auto', maxWidth: '736px', height: '300px' }}>
                <iframe scrolling="no" src={`${iframeUrl}/jobiframe`} style={{ border: '0px none', height: '859px', marginTop: '-86px', width: '100%' }}></iframe>
            </div>
            }
            {servicedetails && territory && !locality && <div style={{ overflow: 'hidden', margin: '15px auto', maxWidth: '736px', height: '300px' }}>
                <iframe scrolling="no" src={`${iframeUrl}/jobiframe` + "/" + servicedetails.name + "/" + territory} style={{ border: '0px none', height: '859px', marginTop: '-86px', width: '100%' }}></iframe>
            </div>
            }
            {servicedetails && territory && locality && <div style={{ overflow: 'hidden', margin: '15px auto', maxWidth: '736px', height: '300px' }}>
                <iframe scrolling="no" src={`${iframeUrl}/jobiframe` + "/" + servicedetails.name + "/" + locality} style={{ border: '0px none', height: '859px', marginTop: '-86px', width: '100%' }}></iframe>
            </div>
            }

            {/* {servicedetails && locality && <div style={{ overflow: 'auto', margin: '15px auto', maxWidth: '736px', height: '326px' }}>
                <iframe scrolling="no" src={`${iframeUrl}/businessiframe` + "/" + servicedetails._id + "/" + locality} style={{ border: '0px none', height: '859px', marginTop: '-10px', width: '100%' }}></iframe>
            </div>
            } */}
            {servicedetails && locality && <div style={{ height: '375px', width: '100%' }}>
                <div style={{ position: 'relative', width: '100%', height: '100%', overflow: 'hidden' }}>
                    <iframe
                        title="Embedded Content"
                        src={`${iframeUrl}/businessiframe` + "/" + servicedetails._id + "/" + locality}
                        width="100%"
                        height="100%"
                    />
                </div>
            </div>}

        </div>
    )
}
export default Right;