import React, { useEffect, useState } from 'react';
import { useParams, useLocation, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { Card, Grid, Typography } from "@material-ui/core";
import { getAllLocallocationAu } from "../../../redux/actions/location"
import { scroller } from "react-scroll";

function ToSeoUrl(url) {
    // make the url lowercase         
    var encodedUrl = url.toString().toLowerCase();
    // replace & with and           
    encodedUrl = encodedUrl.split(/\&+/).join("-and-")
    // remove invalid characters 
    encodedUrl = encodedUrl.split(/[^a-z0-9]/).join("-");
    // remove duplicates 
    encodedUrl = encodedUrl.split(/-+/).join("-");
    // trim leading & trailing characters 
    encodedUrl = encodedUrl.trim('-');
    return encodedUrl;
}
const LocationsListView = () => {
    const [groupedCategories, setGroupedCategories] = useState([]);
    const history = useHistory();
    const location = useLocation();
    const { localLocationAu } = useSelector(({ location }) => location);
    const dispatch = useDispatch();

    let urls = location.pathname.split("/");
    useEffect(() => {
        getProfessions()
    }, [localLocationAu])
    useEffect(() => {
        let urls = location.pathname.split("/");
        dispatch(getAllLocallocationAu(urls[4]));
    }, [])
    let scrollToSection = (scrollId) => {
        scroller.scrollTo(scrollId, {
            duration: 800,
            delay: 0,
            smooth: "easeInOutQuart",
        });
    };

    function navigate(region, locality, postcode) {
        var urlregion = ToSeoUrl(region)
        if (locality) {
            var urllocality = ToSeoUrl(locality)
            history.push("/" + urls[1] + "/" + urls[2] + "/" + urls[3] + "/" + urlregion + "/" + urllocality + "+" + postcode)
        } else {
            history.push("/" + urls[1] + "/" + urls[2] + "/" + urls[3] + "/" + urlregion)
        }
        window.scrollTo(0, 0)
    }

    let getProfessions = async () => {

        let data = localLocationAu.reduce((r, e) => {
            // get first letter of name of current element
            //console.log(r)
            //let group = e.region[0];
            let group = e.region;
            // if there is no property in accumulator with this letter create it
            if (!r[group]) r[group] = { group, children: [e] }
            // if there is push current element to children array for that letter
            else r[group].children.push(e);
            // return accumulator
            return r;
        }, {})
        let result = Object.values(data)
        result.sort(function (a, b) {
            return a.group.localeCompare(b.group);
        })
        //console.log(result)
        setGroupedCategories(result);
    };

    return (
        <div className={'col-xs-12 col-md-12 slp-main-content'}>
            <Card className="p-6" style={{ padding: '2%' }}>
                <Typography variant="h5" className="mb-2">Our Services in </Typography>
                {/* <div className=" flex-row justify-between align-content-center">
                    {groupedCategories.map((item, index) => {
                        return <span key={index} onClick={()=> scrollToSection(item.group)}
                                     className="sm:hover:underline cursor-pointer font-weight-bolder text-primary" style={{margin:'0% 4% 0% 0%'}}>{item.group}</span>
                    })}
                </div> */}

                <div className="flex-col">
                    {groupedCategories.map((item, index) => {
                        if (ToSeoUrl(item.children[index].region) == ToSeoUrl(urls[4])) {
                            return <div className={item.group} key={index} style={{ marginTop: '5vh' }}>
                                <Typography variant="h4" className="mb-4 cursor-pointer" onClick={() => navigate(item.children[index].region)} >{item.children[index].region}</Typography>
                                <Grid container spacing={1}>
                                    {item.children.map((datum, ind) => {
                                        return <Grid item key={ind} xs={12} sm={6} md={4} lg={3}
                                            onClick={() => navigate(datum.region, datum.locality, datum.postcode)}
                                            className="text-primary sm:hover:underline cursor-pointer">
                                            {datum.locality}
                                        </Grid>
                                    })}
                                </Grid>
                            </div>
                        }
                    })}
                </div>
            </Card>
        </div>
    )
}

export default LocationsListView
