import React, { useState, useEffect } from 'react';
import { useParams, useLocation, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import Navbar from './header';
import Banner from './banner';
import LandingLeft from './left_content_landing'
import Right from './right_content'
import { axios } from "../../../api-handling/http-service";
import { rootUrl } from "../../../api-handling/rootUrl";
import './index.css';
import Footer from './footer'
import 'bootstrap/dist/css/bootstrap.min.css';
import { searchPostcode, searchLocality, searchTerritory } from "../../../api-handling/fetchLocationsNz";
import HelmetMetaData from './HelmetForSeo'
import { getAllLocations } from "../../../redux/actions/location"
import { getAllProfessions, getProfessionByName } from "../../../redux/actions/profession"

const LandingNz = () => {
    const history = useHistory();
    const location = useLocation();
    let urls = location.pathname.split("/");
    var { service, state, area, locationss } = useParams();
    let Local = '';
    let Post = '';
    if (locationss) {
        let locarray = locationss.split("+");
        Local = locarray[0].replace('-', ' ');
        Post = locarray[1];
    }
    const { servicedetails } = useSelector(({ profession }) => profession);

    //let [servicedetails, setService] = useState(null);
    let [postcode, setPostcode] = useState(null);
    let [locality, setLocality] = useState(null);
    let [territory, setTerritory] = useState(null);
    const dispatch = useDispatch();

    useEffect(() => {
        if (territory != null && locality != null && postcode != null && servicedetails != null) {
            urlRedirect(postcode, locality, territory, servicedetails);
        }
    }, [postcode, locality, territory, servicedetails]);
    useEffect(() => {
        urlValidate(area, Local, Post, service);
    }, [])


    let urlValidate = async (area, Local, Post, service) => {
        await searchPostcode(Post).then(res => {
            if (res.length) {
                var loc = res[0];
                setPostcode(loc.postcode)
            } else {
                setPostcode(null);
            }
        });
        await searchLocality(Local).then(res => {
            if (res.length) {
                var loc = res[0];
                setLocality(loc.locality)
            } else {
                setLocality(null);
            }
        });
        await searchTerritory(area).then(res => {
            if (res.length) {
                var loc = res[0];
                setTerritory(loc.territory);
            } else {
                setTerritory(null);
            }
        });
        if (servicedetails == null) {
            dispatch(getProfessionByName(service));
        }
    }
    let urlRedirect = (postcode, locality, territory, servicedetails) => {
        if (servicedetails == null) {
            history.push("/" + urls[1] + "/nz")
        }
    }
    return (
        <div>
            <HelmetMetaData servicedetails={servicedetails} locality={locality} territory={territory} state={state} />
            <Navbar />
            <Banner servicedetails={servicedetails} locality={locality} territory={territory} state={state} />
            <div className={'row'}>
                <LandingLeft servicedetails={servicedetails} locality={locality} territory={territory} state={state} />
                <Right servicedetails={servicedetails} locality={locality} territory={territory} state={state} />
            </div>
            <Footer />
        </div>
    );
}
export default LandingNz;
