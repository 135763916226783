// import React from 'react';
// import ReactDOM from 'react-dom';
// import { Switch, BrowserRouter, Router } from 'react-router-dom';
// import { render } from 'react-snapshot';
// import { createBrowserHistory } from 'history';
// import './index.css';
// import App from './App';
// const history = createBrowserHistory();

// render(<Router history={history}><App /></Router>, document.getElementById('root'));

import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';

import App from './App'; // Your React app
const history = createBrowserHistory();

ReactDOM.hydrate(<BrowserRouter history={history}><App /></BrowserRouter>, document.getElementById('root'));