import React, { Suspense } from 'react';
// import Map from './map';
import MapDist from './mapDist';
import Map from './map';

// const Map = React.lazy(() => import("./map"))


const Left = ({ servicedetails, locality, dist, territory }) => {

    return (
        <div className={'col-xs-12  col-sm-12 col-md-12 col-lg-8 slp-main-content'}>
            <img loading="lazy" style={{ float: 'right', margin: '0px 20px' }}
                src={servicedetails?.imageUrl}
                alt={`${servicedetails?.name} Services`} width="300" height="200" />
            <h3>{servicedetails?.name} in {locality ? locality : '' || dist ? dist : '' || territory ? territory : ''}</h3>
            {territory}
            <p>
                Need {servicedetails?.name} in {locality ? locality : '' || dist ? dist : '' || territory ? territory : ''} ?  Call TradingSeek  {locality ? locality : '' || dist ? dist : '' || territory ? territory : ''} for all your domestic and commercial {servicedetails?.name} services in {locality ? locality : '' || dist ? dist : '' || territory ? territory : ''}.  Our professional team are available 7 days a week; book a free
                quote online or call 123 456.
            </p>
            <h3>{servicedetails?.name} in {locality ? locality : '' || dist ? dist : '' || territory ? territory : ''}</h3>

            <p>
                Need {servicedetails?.name} in {locality ? locality : '' || dist ? dist : '' || territory ? territory : ''}?  Call TradingSeek {locality ? locality : '' || dist ? dist : '' || territory ? territory : ''} for all your domestic and commercial {servicedetails?.name} services in {locality ? locality : '' || dist ? dist : '' || territory ? territory : ''}.  Our professional team are available 7 days a week; book a free
                quote online or call 123 456.
            </p>
            <h3>{servicedetails?.name} in {locality ? locality : '' || dist ? dist : '' || territory ? territory : ''}</h3>

            <p>
                Need {servicedetails?.name} in {locality ? locality : '' || dist ? dist : '' || territory ? territory : ''}?  Call TradingSeek {locality ? locality : '' || dist ? dist : '' || territory ? territory : ''} for all your domestic and commercial {servicedetails?.name} services in {locality ? locality : '' || dist ? dist : '' || territory ? territory : ''}.  Our professional team are available 7 days a week; book a free
                quote online or call 123 456.
            </p>
            <div>
                {territory != '' &&
                    // <Suspense fallback={'loading...'}>
                    <Map territory={territory} />
                    // </Suspense>
                }
                {/* { territory != '' && dist != '' && <MapDist dist={dist} territory={territory}/>}  */}
            </div>
        </div>
    );
}
export default Left;