import { rootUrl } from "./rootUrl";
import { axios } from "./http-service";

function ToSeoUrl(url) {
    if (url != "") {
        var encodedUrl = url?.toString().toLowerCase();
        encodedUrl = encodedUrl?.split(/\&+/).join("-and-")
        encodedUrl = encodedUrl?.split(/[^a-z0-9]/).join("-");
        encodedUrl = encodedUrl?.split(/-+/).join("-");
        encodedUrl = encodedUrl?.trim('-');
        return encodedUrl;
    }
}
// const searchPostcode = async (searchText, data) => {
//     let matches = await data.filter(item => {
//         return (item.pincode == searchText)
//     });

//     if (matches.length === 0) {
//         matches = [];
//     }
//     return matches;
// };

const searchPostcode = async (pincode) => {
    let matches;
    await axios.post(`${rootUrl}/in/search-postcode`, {
        pincode
    }).then(({ data }) => {
        matches = data.data;
    }).catch(function (error) {
        console.log('error=', error.message)
    })
    return matches;
}

// const searchDistrict = async (searchText, data) => {
//     let matches = await data.filter(item => {
//         return (ToSeoUrl(item.districts) == ToSeoUrl(searchText))
//     });

//     if (matches.length === 0) {
//         matches = [];
//     }
//     return matches;
// };

const searchDistrict = async (districts) => {
    let matches;
    await axios.post(`${rootUrl}/in/search-districts`, {
        districts
    }).then(({ data }) => {
        matches = data.data;
    }).catch(function (error) {
        console.log('error=', error.message)
    })
    return matches;
}

// const searchLocality = async (searchText, data) => {
//     let matches = await data.filter(item => {
//         return (ToSeoUrl(item.area) == ToSeoUrl(searchText))
//     });

//     if (matches.length === 0) {
//         matches = [];
//     }
//     return matches;
// };

const searchLocality = async (area) => {
    let matches;
    await axios.post(`${rootUrl}/in/search-locality`, {
        area
    }).then(({ data }) => {
        matches = data.data;
    }).catch(function (error) {
        console.log('error=', error.message)
    })
    return matches;
}

// const searchTerritory = async (searchText, data) => {
//     let matches = await data.filter(item => {

//         return (ToSeoUrl(item.state) == ToSeoUrl(searchText))
//     });

//     if (matches.length === 0) {
//         matches = [];
//     }
//     return matches;
// };

const searchTerritory = async (state) => {
    let matches;
    await axios.post(`${rootUrl}/in/search-state`, {
        state
    }).then(({ data }) => {
        matches = data.data;
    }).catch(function (error) {
        console.log('error=', error.message)
    })
    return matches;
}

export { searchPostcode, searchLocality, searchTerritory, searchDistrict }
