import React, { useState, useEffect, Suspense } from 'react';
import { useParams, useLocation, useHistory } from "react-router-dom";
import Navbar from './header';
import Banner from './banner';
import LocalLeft from './left_content_local'
import Right from './right_content'
import HelmetMetaData from './HelmetForSeo'

import './index.css';

import Footer from './footer'
import 'bootstrap/dist/css/bootstrap.min.css';
// const Right = React.lazy(() => import("./right_content"))
// const Banner = React.lazy(() => import("./banner"))
// const LocalLeft = React.lazy(() => import("./left_content_local"))


const AllCountry = () => {

    let [servicedetails, setService] = useState(null);
    let [locality, setLocality] = useState(null);
    let [territory, setTerritory] = useState(null);
    let [state, setState] = useState(null);

    const history = useHistory();
    const location = useLocation();
    let urls = location.pathname.split("/");
    //console.log(location.pathname);

    useEffect(() => {
        urlRedirect()
    }, [])




    let urlRedirect = () => {
        if (urls[1] != "local") {
            history.push("/")
        }
    }

    return (
        <div>
            <HelmetMetaData />
            {/* Test */}
            <Navbar />
            {/* <Suspense fallback={<div>Loading...</div>} >*/}
            <Banner servicedetails={servicedetails} locality={locality} territory={territory} state={state} />
            {/*  </Suspense>*/}
            <div className={'row'}>
                {/*     <Suspense fallback={<div>Loading...</div>} >*/}
                <LocalLeft servicedetails={servicedetails} locality={locality} territory={territory} state={state} />
                {/*  </Suspense>*/}
                {/*  <Suspense fallback={<div>Loading...</div>} >*/}
                <Right servicedetails={servicedetails} locality={locality} territory={territory} state={state} />
                {/* </Suspense>*/}
            </div>
            <Footer />
        </div>
    );
}
export default AllCountry;
