export const SET_ALL_LOCATION = 'set_all_loacation'

export const SET_ALL_STATE_IN = 'set_all_state_in'
export const SET_ALL_DISTRICTS_IN = 'set_all_districts_in'
export const SET_ALL_LOCAL_LOCATION_IN = 'set_all_local_location_in'

export const SET_ALL_REGION_AU = 'set_all_region_au'
export const SET_ALL_LOCAL_LOCATION_AU = 'set_all_local_location_au'

export const SET_ALL_REGION_NZ = 'set_all_region_nz'
export const SET_ALL_LOCAL_LOCATION_NZ = 'set_all_local_location_nz'

export const SET_IP_INFO = 'set_ip_info'
export const SET_IFRAME_URL = 'set_iframe_url'
