import {
    SET_ALL_LOCATION,
    SET_ALL_STATE_IN, SET_ALL_DISTRICTS_IN, SET_ALL_LOCAL_LOCATION_IN,
    SET_ALL_REGION_AU, SET_ALL_LOCAL_LOCATION_AU,
    SET_ALL_REGION_NZ, SET_ALL_LOCAL_LOCATION_NZ,
    SET_IP_INFO, SET_IFRAME_URL
} from '../constants/locationActionTypes'
const INIT_STATE = {
    locations: [],
    stateIn: [],
    districtIn: [],
    localLocationIn: [],
    regionAu: [],
    localLocationAu: [],
    regionNz: [],
    localLocationNz: [],
    inInfo: {},
    iframeUrl: ""
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case SET_ALL_LOCATION: {
            return {
                ...state,
                locations: action.payload,
            };
        }
        case SET_ALL_STATE_IN: {
            return {
                ...state,
                stateIn: action.payload,
            };
        }
        case SET_ALL_DISTRICTS_IN: {
            return {
                ...state,
                districtIn: action.payload,
            };
        }
        case SET_ALL_LOCAL_LOCATION_IN: {
            return {
                ...state,
                localLocationIn: action.payload,
            };
        }
        case SET_ALL_REGION_AU: {
            return {
                ...state,
                regionAu: action.payload,
            };
        }
        case SET_ALL_LOCAL_LOCATION_AU: {
            return {
                ...state,
                localLocationAu: action.payload,
            };
        }
        case SET_ALL_REGION_NZ: {
            return {
                ...state,
                regionNz: action.payload,
            };
        }
        case SET_ALL_LOCAL_LOCATION_NZ: {
            return {
                ...state,
                localLocationNz: action.payload,
            };
        }
        case SET_IP_INFO: {
            return {
                ...state,
                inInfo: action.payload,
            };
        }
        case SET_IFRAME_URL: {
            return {
                ...state,
                iframeUrl: action.payload,
            };
        }
        default:
            return state;
    }
}