import React from 'react';
import Map from './map';

const Left = ({ servicedetails, locality, territory }) => {
    return (
        <div className={'col-xs-12  col-sm-12 col-md-12 col-lg-8 slp-main-content'}>
            <img loading="lazy" style={{ float: 'right', margin: '0px 20px' }}
                src={servicedetails?.imageUrl}
                alt={`${servicedetails?.name} Services`} width="300" height="200" />
            <h3>{servicedetails?.name} in {locality ? locality : '' || territory ? territory : ''}</h3>

            <p>
                Need {servicedetails?.name} in {locality ? locality : '' || territory ? territory : ''}?  Call TradingSeek {locality ? locality : '' || territory ? territory : ''} for all your domestic and commercial
                cleaning services in {locality ? locality : '' || territory ? territory : ''}.  Our professional team are available 7 days a week; book a free
                quote online or call 123 456.
            </p>
            <h3>{servicedetails?.name} in {locality ? locality : '' || territory ? territory : ''}</h3>

            <p>
                Need {servicedetails?.name} in {locality ? locality : '' || territory ? territory : ''}?  Call TradingSeek {locality ? locality : '' || territory ? territory : ''} for all your domestic and commercial
                cleaning services in {locality ? locality : '' || territory ? territory : ''}.  Our professional team are available 7 days a week; book a free
                quote online or call 123 456.
            </p>
            <h3>{servicedetails?.name} in {locality ? locality : '' || territory ? territory : ''}</h3>

            <p>
                Need {servicedetails?.name} in {locality ? locality : '' || territory ? territory : ''}?  Call TradingSeek {locality ? locality : '' || territory ? territory : ''} for all your domestic and commercial
                cleaning services in {locality ? locality : '' || territory ? territory : ''}.  Our professional team are available 7 days a week; book a free
                quote online or call 123 456.
            </p>
            <div>

                {territory != '' && <Map />}
            </div>
        </div>
    );
}
export default Left;